/* vietnamese */
@font-face {
  font-family: '__Maven_Pro_8dacc0';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/f77a1297c32c3ea4-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Maven_Pro_8dacc0';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/0a111e574101093a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Maven_Pro_8dacc0';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  src: url(/_next/static/media/167ad745238e18bd-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Maven_Pro_Fallback_8dacc0';src: local("Arial");ascent-override: 93.12%;descent-override: 20.26%;line-gap-override: 0.00%;size-adjust: 103.63%
}.__className_8dacc0 {font-family: '__Maven_Pro_8dacc0', '__Maven_Pro_Fallback_8dacc0';font-style: normal
}.__variable_8dacc0 {--font-sans: '__Maven_Pro_8dacc0', '__Maven_Pro_Fallback_8dacc0'
}

/* vietnamese */
@font-face {
  font-family: '__Space_Grotesk_587f35';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/62328fecf9e80426-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Space_Grotesk_587f35';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/c7eb187887c48af6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Space_Grotesk_587f35';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/2d141e1a38819612-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Space_Grotesk_Fallback_587f35';src: local("Arial");ascent-override: 89.71%;descent-override: 26.62%;line-gap-override: 0.00%;size-adjust: 109.69%
}.__className_587f35 {font-family: '__Space_Grotesk_587f35', '__Space_Grotesk_Fallback_587f35';font-style: normal
}.__variable_587f35 {--font-brand: '__Space_Grotesk_587f35', '__Space_Grotesk_Fallback_587f35'
}

